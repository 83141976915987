import { Ability } from '@casl/ability';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import { AbilityContext } from './components/common/Authorization';
// import VersionAutoUpdater from './components/VersionAutoUpdater/VersionAutoUpdater';
import { ErrorBoundary } from './features/errorBoundary';
import { ChartColorMappingContextProvider } from './hooks/useChartColorConfig';
import MobileRoutes from './MobileRoutes';
import { ActiveJobProvider } from './providers/ActiveJobProvider';
import { AppProvider } from './providers/AppProvider';
import MantineProvider from './providers/MantineProvider';
import ReactQueryProvider from './providers/ReactQueryProvider';
// import ThirdPartyProvider from './providers/ThirdPartyServicesProvider';
import { TrackingProvider } from './providers/TrackingProvider';
import Routes from './Routes';

import './styles/inframe.css';

const defaultAbility = new Ability();

// const isMobile =
//     /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//         navigator.userAgent,
//     ) || window.innerWidth < 768;

const isMobile = window.innerWidth < 768;

const isMinimalPage = window.location.pathname.startsWith('/minimal');

/* Nanoheal - embeds fix */
let classArray: string[] = [];
if (`${window.location.hash}`.includes(`HHauthHH`)) {
    const authW = window.location.hash.replace('#', '').split(`HHauthHH`);
    console.log('auth:', authW);

    const auth = {
        email: authW[0],
        password: authW[1],
    };
    // @ts-ignore
    window._auth = auth;

    window.sessionStorage.setItem('_auth', JSON.stringify(auth));
    classArray.push('has-auth');
} else if (window.location.hash) {
    try {
        const data = JSON.parse(
            decodeURIComponent(window.location.hash.replace('#', '')),
        );
        if (data) {
            const auth = {
                email: data?.e,
                password: data?.pw,
            };
            classArray.push('has-auth');

            window.sessionStorage.setItem('_auth', JSON.stringify(auth));
            // @ts-ignore
            window._auth = auth;
        }
    } catch (e) {
        console.error('Error parsing hash:', window.location.hash, e);
    }
} else if (window.sessionStorage.getItem('_auth')) {
    try {
        // @ts-ignore
        window._auth = JSON.parse(window.sessionStorage.getItem('_auth'));
        classArray.push('has-auth');
    } catch (e) {
        console.error(
            'Error parsing sessionStorage:',
            window.sessionStorage.getItem('_auth'),
            e,
        );
    }
}

classArray.push(window.parent !== window ? 'in-iframe' : 'no-iframe');

const App = () => (
    <>
        <Helmet>
            <title>Lightdash</title>
        </Helmet>
        <div className={classArray.join(` `)}>
            <ReactQueryProvider>
                <MantineProvider>
                    <Router>
                        <AppProvider>
                            {/* Nanoheal - embeds fix - no analitics  */}
                            {/* <VersionAutoUpdater /> */}
                            {/* <ThirdPartyProvider
                                enabled={isMobile || !isMinimalPage}
                            > */}
                            <ErrorBoundary wrapper={{ mt: '4xl' }}>
                                <TrackingProvider
                                    enabled={isMobile || !isMinimalPage}
                                >
                                    <AbilityContext.Provider
                                        value={defaultAbility}
                                    >
                                        <ActiveJobProvider>
                                            <ChartColorMappingContextProvider>
                                                {isMobile ? (
                                                    <MobileRoutes />
                                                ) : (
                                                    <Routes />
                                                )}
                                            </ChartColorMappingContextProvider>
                                        </ActiveJobProvider>
                                    </AbilityContext.Provider>
                                </TrackingProvider>
                            </ErrorBoundary>
                            {/* </ThirdPartyProvider> */}
                        </AppProvider>
                    </Router>
                </MantineProvider>

                <ReactQueryDevtools initialIsOpen={false} />
            </ReactQueryProvider>
        </div>
    </>
);

export default App;
